// Parent Component need to declare & import ChartLibraryService
import JsonLint from 'jsonlint'
// import JsonValidator from 'json-validator'

export const QueryValidationMixin = {
  data: () => ({
    // Local properties
    necessaries: [
      { name: 'sortable', message: "Property 'sortable' is not defined.\nAt least one 'sortable' column should be defined." }
    ],
    limitedWordArr: ['DROP', 'INSERT', 'UPDATE'],
    // limitedWordArr: ['DROP', 'INSERT', 'UPDATE', 'DELETE'],
    notInvolvedQeuryString: ['Filters2Clause', 'WhereConditions', 'SortOptions']
  }),
  methods: {
    extractPureSql(sql) {
      this.notInvolvedQeuryString.forEach(v => {
        let startIndex = -1
        let endIndex = -1
        let go = true

        while (go) {
          startIndex = sql.indexOf(v)

          if(startIndex > -1) {
            endIndex = sql.indexOf(')', startIndex)
            sql = sql.substring(0, startIndex - 1) + ' ' + sql.substring(endIndex + 1)

          } else break
        }
      })

      return sql
    },
    getQueryParts(name) {
      if(!this[name]) return ''
      return this[name].substring(0, 50) + ' ...'
    },

    // result [Yes, No]: validate query, and then return its result
    // json [Yes, No]: return result in JSON format
    validateSql(query, result='N', json='N', rows=30) {

      let invalid_ = { valid: false, message: 'Invalid query script!' }

      if(!query || !query.trim()) return new Promise((resolve) => { resolve(invalid_) })

      let compareQ = query.toUpperCase()
      let restricted_ = ''
      if(this.limitedWordArr.some(w => { 
        restricted_ = w
        return compareQ.indexOf(w) > -1 
      })) return new Promise(
        (resolve) => { resolve({
          valid: false,
          message: `For the security reason, ${restricted_} keyword cannot be contained.`
        })}
      )

      let filteredQueryString = query
      filteredQueryString = this.extractPureSql(filteredQueryString) 
      let params = new URLSearchParams()
      params.append('query', filteredQueryString)
      params.append('result', result)
      params.append('json', json)
      params.append('rows', rows)

      return new Promise((resolve) => {
        this.chartLibraryService.getQueryValidator(params, res => {
          if(!res) {
            resolve({
              valid: true,
              message: 'No result from the query. It may not the error.'
            })
            return
          }

          resolve({
            valid: res.valid,
            message: res.message,
            results: result ? res.results : []
          })
        })
      })
    },
    validateJson(json, deep=false) {
      // if(deep) {
      //   if(json.indexOf('sortable') < 0) return {
      //     valid: false,
      //     message: "Property 'sortable' is not defined.\nAt least one 'sortable' column should be defined."
      //   }
      // }

      try {
        JsonLint.parse(json)

      } catch(err) {
        return {
          valid: false,
          message: err.message
        }
      }

      // TO DO : 
      // - Validate the elements available & its value-option valid
      // - Check the value field empty
      // - Check at least one 'sortable' available
      // - Check type 'code' placed in the group level
      // - Check visible false for type 'code'
      
      return {
        valid: true,
        message: '',
        json: JSON.stringify(JSON.parse(json), null, 4)
      }
    },
  }
}